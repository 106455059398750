.hero {
  position: relative;
  background-position: 50% 50%;
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);
  h1 { font-size: map-get($base, font-size-h1-xl); }
  h2 { font-size: map-get($base, font-size-h2-xl); }
  h3 { font-size: map-get($base, font-size-h3-xl); }
  h4 { font-size: map-get($base, font-size-h4-xl); }
  h5 { font-size: map-get($base, font-size-h5-xl); }
  h6 { font-size: map-get($base, font-size-h6-xl); }
  p  { font-size: map-get($base, font-size-xl); }
  @include media-breakpoint-down(lg) {
    h1 { font-size: map-get($base, font-size-h1-lg); }
    h2 { font-size: map-get($base, font-size-h2-lg); }
    h3 { font-size: map-get($base, font-size-h3-lg); }
    h4 { font-size: map-get($base, font-size-h4-lg); }
    h5 { font-size: map-get($base, font-size-h5-lg); }
    h6 { font-size: map-get($base, font-size-h6-lg); }
    p  { font-size: map-get($base, font-size-lg); }
  }
  @include media-breakpoint-down(md) {
    h1 { font-size: map-get($base, font-size-h1-sm); }
    h2 { font-size: map-get($base, font-size-h2-sm); }
    h3 { font-size: map-get($base, font-size-h3-sm); }
    h4 { font-size: map-get($base, font-size-h4-sm); }
    h5 { font-size: map-get($base, font-size-h5-sm); }
    h6 { font-size: map-get($base, font-size-h6-sm); }
    p  { font-size: map-get($base, font-size); }
  }
  background-size: cover;

}

.hero--center {
  text-align: center;
  .menu {
    @extend .menu--center;
  }
}

.hero--light {
  @extend .text--light;
}

.hero--dark {
  @extend .text--dark;
}

.hero__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  img {
    width: 100%;
    height: 100%;
    opacity: 0.25;
    object-fit: cover;
  }
}

.hero__content {
  margin: map-get($spacers, 5);
  z-index: 1;
  @include media-breakpoint-down(lg) {
    margin: map-get($spacers, 5) map-get($spacers, 4);
  }
  @include media-breakpoint-down(md) {
    margin: map-get($spacers, 4) map-get($spacers, 3);
  }
}

.heros {
  & > .hero {
    margin: map-get($spacers, 5);
    @include media-breakpoint-down(lg) {
      margin: map-get($spacers, 3);
    }
    @include media-breakpoint-down(md) {
      margin: map-get($spacers, 2) 0;
    }
  }
}
