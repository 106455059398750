.article__info {
  font-size: map-get($base, font-size-sm);
  color: $text-color-function;
  .left-col {
    float: left;
    @include media-breakpoint-down(md) {
      float: none;
    }
  }
  .right-col {
    float: right;
    margin-left: map-get($button, padding-x-sm);
    @include media-breakpoint-down(md) {
      float: none;
    }
    & > li {
      &:not(:last-child) {
        @include split-line(right, default, $text-color-l);
        padding-right: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);
        line-height: map-get($base, line-height-xs);
      }
    }
  }
  li {
    color: $text-color-function !important;
  }
}
